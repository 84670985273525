module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Gecko Project","short_name":"Gecko","description":"Investigative reporting on corruption, climate, rainforests and rights.","lang":"en","display":"standalone","icon":"src/img/gecko-favicon.svg","start_url":"/","background_color":"#1C1917","theme_color":"#E7333F","localize":[{"start_url":"/id/","lang":"id","name":"The Gecko Project","short_name":"Gecko","description":"Laporan investigasi tentang korupsi, perubahan iklim, hutan, dan hak atas tanah maupun hak asasi manusia (HAM)."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c889afa295ad787c0f665de966eb293a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","id"],"defaultLanguage":"en","siteUrl":"https://thegeckoproject.org","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":":"},"pages":[{"matchPath":"/:lang?/formats/:title/","getLanguageFromPath":true,"excludeLanguages":["en","id"]},{"matchPath":"/:lang?/topics/:title/","getLanguageFromPath":true,"excludeLanguages":["en","id"]},{"matchPath":"/:lang?/articles/:title/","getLanguageFromPath":true,"excludeLanguages":["en","id"]},{"matchPath":"/:lang?/about/","getLanguageFromPath":true,"excludeLanguages":["en","id"]},{"matchPath":"/partners/:name/","language":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false,"className":"article-header","removeAccents":true,"elements":["h1","h2","h3","h4"],"offsetY":0},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-08SPRN705G"],"pluginConfig":{"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/cms/cms.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
